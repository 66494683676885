<template>
  <el-container>
    <el-header>
      <el-row>
        <el-col :span="10">
          <div><img :src="require('@/assets/logo.png')" style="vertical-align: middle; block-size: 40px;"> Titan计数器--数据同步分享</div>
        </el-col>
        <el-col :span="10" :offset="4" style="text-align: end;">
          <div> 本平台已累计为用户提供数据交互：{{request_count}}次</div>
        </el-col>
      </el-row>
    </el-header>
    <el-main>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="24" :lg="14">
          <div style="border-radius: 5px; text-align: start; padding: 15px; background-color: #eee!important;">
            <span style="color: red;">{{tips}}</span>
          </div>
          <div style="border-radius: 5px; block-size: 30px; line-height: 30px; text-align: start; padding: 15px; background-color: #eee!important;">
            <span style="color: #666!important;">当前正在查看分组：<el-button size="small" type="success">默认分组</el-button></span>
            <span style="float:inline-end; color: red;">{{time}} 秒之后自动刷新</span>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="24" :lg="14">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" >
              <div style="border-radius: 5px; padding: 10px 30px; background-color: #393D49!important; color: #fff; text-align: start;">
                <div style="display:inline-flex;">
                  <el-icon size="50px" color="#FFFFFF">
                    <User />
                  </el-icon>
                  <i class="el-icon-user" style="font-size: 50px; color: #FFFFFF;"></i>
                </div>
                <div style="display:inline-block; padding: 0 10px;">
                  <span style="font-size: 30px;">{{account_count}}</span>
                  <br><span>帐号总数量</span>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" >
              <div style="border-radius: 5px; padding: 10px 30px; background-color: #009688!important; color: #fff; text-align: start;">
                <div style="display:inline-flex;">
                  <el-icon size="50px" color="#FFFFFF">
                    <CirclePlus />
                  </el-icon>
                </div>
                <div style="display:inline-block; padding: 0 10px;">
                  <span style="font-size: 30px;">{{reg_count}}</span>
                  <br><span>申请总数量</span>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <div style="border-radius: 5px; padding: 10px 30px; background-color: #1E9FFF!important; color: #fff; text-align: start;">
                <div style="display:inline-block;">
                  <el-icon size="50px" color="#FFFFFF">
                    <CircleCheck />
                  </el-icon>
                </div>
                <div style="display:inline-block; padding: 0 10px;">
                  <span style="font-size: 30px;">{{pass_count}}</span>
                  <br><span>同意总数量</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="24" :lg="14">
          <el-card class="box-card">
            <slot name="header" class="clearfix">
              <el-button style="float: inline-start;" type="primary" @click="batchDel" size="middle">删除</el-button>
              <el-form :inline="true" :model="formData" class="demo-form-inline">
                <el-form-item>
                  <el-select v-model="formData.userState" placeholder="全部" style="width:150px" @change="changeStatus">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="formData.wxId" placeholder="请输入微信ID"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="formData.name" placeholder="请输入昵称"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="formData.memo" placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit">搜索</el-button>
                </el-form-item>
              </el-form>
            </slot>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
                @sort-change="sortChange">
              <el-table-column
                  type="selection"
                  width="55">
              </el-table-column>
              <el-table-column
                  type="index"
                  prop="index"
                  label="序号"
                  width="50"
                  :index="getIndex">
              </el-table-column>
              <el-table-column
                  :show-overflow-tooltip="true"
                  prop="wxId"
                  sortable="custom"
                  label="微信ID">
              </el-table-column>
              <el-table-column
                  :show-overflow-tooltip="true"
                  prop="name"
                  label="昵称">
              </el-table-column>
              <el-table-column
                  prop="applyCount"
                  sortable="custom"
                  label="申请数">
              </el-table-column>
              <el-table-column
                  prop="passCount"
                  sortable="custom"
                  label="通过数">
              </el-table-column>
              <el-table-column
                  prop="userState"
                  sortable="custom"
                  label="状态">
                <template #default="scope">
                  <span :style="scope.row.userState === '在线' ? 'color: green;' : 'color: red;'">{{ scope.row.userState }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="memo"
                  label="备注">
              </el-table-column>
              <el-table-column
                  prop="loginTime"
                  sortable="custom"
                  label="登录时间">
              </el-table-column>
              <el-table-column
                  prop="lastApplyTime"
                  sortable="custom"
                  label="最后申请">
              </el-table-column>
            </el-table>
            <el-pagination
                style="float: inline-start; margin:10px 0;"
                background
                layout="prev, pager, next"
                @current-change="pageChange"
                :page-size="formData.limit"
                :current-page="formData.page"
                :total="total">
            </el-pagination>
          </el-card>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="24" :lg="14">
          <div style="border-inline-start: 5px solid #009688; border-radius: 5px; block-size: 30px; line-height: 30px; text-align: start; padding: 10px; background-color: #eee!important; font-size: 16px;">
            <i class="el-icon-message-solid"></i><span> 感谢使用Titan计数器，我们的宗旨是打造全网最公平，最稳定的数据统计平台。</span>
          </div>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
  <el-footer style="inline-size: 200px; margin:auto;"><a target="_blank" href="https://www.miit.gov.cn/" rel="nofollow">蜀ICP备20002324号-2</a ></el-footer>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import axios from '@/api/axios';
import { ElMessage, ElMessageBox } from 'element-plus';

const formData = ref({
  userState: '',
  wxId: '',
  name: '',
  memo: '',
  orderByNameStr: '',
  orderBySortStr: '',
  password: '',
  page: 1,
  limit: 10
})
const options = [{
  value: '',
  label: '全部'
},{
  value: '在线',
  label: '在线'
}, {
  value: '离线',
  label: '离线'
}];
const tableData = ref([]);
const request_count = ref(0);
const account_count = ref(0);
const pass_count = ref(0);
const reg_count = ref(0);
const total = ref(0);
const time = ref(19);
const accounts = ref([]);
const tips = '关于禁止使用本软件从事网络违法犯罪行为的公告.告广大用户：接上级部门整改通知，为深入配合相关部门开展国家“雷霆•专项行动”，打击网络赌博诈骗现象，杜绝网络违法犯罪，同时保障公民用网安全，“Titan”将一如既往地针对暴力、反动、赌博、诈骗、淫秽色情等有害信息进行全面清理。在此“Titan”呼吁广大用户自觉守法、相互监督，踊跃举报违法内容，与“Titan”一起支持国家雷霆行动，杜绝网络违法犯罪，共同打造绿色健康的机器人环境。对于已确定的违法犯罪行为，我们将配合网监部门及公安机关坚决查处.“Titan计数器”是一款智能机器人软件，严禁将“Titan计数器”用于违法用途（如发送广告/群发/诈骗、色情、政治等内容）';

// 在组件创建时执行的操作
onMounted(() => {
  console.log('组件创建完成，执行初始化操作...');
  getCookie()
  getCount()
  timer()
});

const sortChange = (column) => {
  formData.value.orderByNameStr = column.prop;
  formData.value.orderBySortStr = column.order;
  getCount()
}
const changeStatus = (status) => {
  console.log(status)
  getCount()
}
const onSubmit = () => {
  getCount()
}
const batchDel = () => {
  if (accounts.value.length === 0) {
    ElMessage('请选择要删除的记录')
  }
  else {
    ElMessageBox.prompt('敏感操作，请输入该账号的用户名（此操作会清空所有加粉记录.包括过滤数据.并无法恢复!!!）', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    }).then(({ value }) => {
      if (value !== '') {
        console.log(value)
        // const params = {username: value, accounts: accounts.value}
        // axios.post('/api/del', params).then(res=> {
        //   if (res.data.code === 0) {
        //     ElMessage('删除成功')
        //     getCount()
        //   } else {
        //     ElMessage(res.data.msg)
        //   }
        // }).catch(error => {
        //   console.log(error)
        // })
      }
    }).catch(() => {

    });
  }
}
const handleSelectionChange = (val) => {
  console.log(val)
  accounts.value = []
  val.forEach(element => {
    accounts.value.push(element.id)
  });
}
const getCount = () => {
  axios.post('/api/info').then(res=> {
    if (res.data.code === 0) {
      //
      request_count.value = res.data.data.totalNum || 0
      account_count.value = res.data.data.accountNum || 0
      pass_count.value = res.data.data.totalPassCount || 0
      reg_count.value = res.data.data.totalApplyCount || 0

      getList()
    } else if (res.data.code === 222) {
      formData.value.password = ''
      this.$prompt(res.data.msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        clearCookie();
        if (value !== '') {
          setCookie(value, 30)
          getCookie()
          getCount()
        }
      }).catch(() => {

      });
    } else {
      ElMessage.error(res.data.msg)
      formData.value.password = ''
      request_count.value = 0
      account_count.value = 0
      pass_count.value = 0
      reg_count.value = 0
      tableData.value = []
      total.value = 0
    }
  }).catch(error => {
    console.log(error)
  })
}
const getList = () => {
  axios.post('/api/list', formData.value).then(res=> {
    if (res.data.code === 0) {
      tableData.value = res.data.data || []
      total.value = res.data.count || 0
    } else {
      ElMessage.error(res.data.msg)
      tableData.value = []
      total.value = 0
    }
  }).catch(error => {
    console.log(error)
  })
}
const pageChange = (currentPage) => {
  formData.value.page = currentPage
  getCount()
}
const getIndex = ($index) => {
  return (formData.value.page - 1) * formData.value.limit + $index + 1
}
const timer = () => {
  return setInterval(()=>{
    if (time.value === 0) {
      /*if (formData.value.password !== '') {
          getCount()
      }*/
      getCount()

      ElMessage({
        message: '刷新成功',
        type: 'success',
      })
      time.value = 19
    } else {
      time.value = time.value - 1
    }
  }, 1000)
}
//设置cookie
const setCookie = (password, exdays) => {
  var exdate = new Date(); //获取时间
  exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
  //字符串拼接cookie
  window.document.cookie =
      "bca7d6d78ca3441a778f0b73ef1230c443dcbe84_password" + "=" + password + ";path=/;expires=" + exdate.toGMTString();
}
//读取cookie
const getCookie = () => {
  if (document.cookie.length > 0) {
    var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
    for (var i = 0; i < arr.length; i++) {
      var arr2 = arr[i].split("="); //再次切割
      //判断查找相对应的值
      if (arr2[0] == "bca7d6d78ca3441a778f0b73ef1230c443dcbe84_password") {
        formData.value.password = arr2[1]; //保存到保存数据的地方
      }
    }
  }
}
//清除cookie
const clearCookie = () => {
  setCookie("", -1); //修改值都为空，天数为负1天就好了
}

// 在组件即将销毁前执行的操作
onBeforeUnmount(() => {
  console.log('组件即将销毁，执行清理工作...');
  clearInterval(timer())
});
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: rgb(204,228,207);
}
.el-header {
  background-color: #2F4056!important;
  color: #fff;
  line-height: 60px;
  font-size: 14px;
}
.el-main {
  color: #333;
  text-align: center;
}
.el-col {
  margin-block-end: 10px;
}
</style>
