import axios from 'axios';

const instance = axios.create({
    // baseURL: 'http://titan.vttt.cn', // 设置请求的基础 URL
    baseURL: 'http://titan1.vttt.cn', // 设置请求的基础 URL
    timeout: 10000, // 设置超时时间
    headers: {
        'Content-Type': 'application/json',
        // 在这里可以设置其他默认的请求头
    }
});

export default instance;
